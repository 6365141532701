import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { checkAuth, login, logout } from '../api/auth';
import AuthContext from '../contexts/AuthContext';

import Loading from './common/Loading';
import Router from './Router/Router';
import '../styles/App.css';

const App = () => {
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const loginHandler = async ({ username, password }) => {
    setLoading(true);

    try {
      const loggedIn = await login({ username, password });
      setAuthorized(loggedIn);

      if (!loggedIn) {
        message.warn('Неправильный логин и/или пароль');
      }
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось войти');
    }
    finally {
      setLoading(false);
    }
  };

  const logoutHandler = async () => {
    setLoading(true);

    await logout();
    setAuthorized(false);
    setLoading(false);
  };

  const checkAuthHandler = async () => {
    const isAuth = await checkAuth();
    setAuthorized(isAuth);
    setLoading(false);
  };

  useEffect(() => {
    checkAuthHandler();
  }, []);

  if (loading) return <Loading />;

  return (
    <AuthContext.Provider value={{
      authorized,
      login: loginHandler,
      logout: logoutHandler,
      checkAuth: checkAuthHandler
    }}>
      <div className="App">
        <Router authorized={authorized} />
      </div>
    </AuthContext.Provider>
  );
};

export default App;
