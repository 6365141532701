import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { fetchPayouts } from '../../../api/payouts';
import { IPayout } from '../../../types/payouts';

const PayoutsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [payouts, setPayouts] = useState<IPayout[]>();

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const payouts = await fetchPayouts();
      setPayouts(payouts);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось загрузить данные');
    }
    finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (_, { user }) => {
        const { name = '—', username } = user;
        return username ? <a href={`https://t.me/${username}`}>{name}</a> : name;
      }
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (_, { user }) => user?.phoneNumber ?? '—'
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => amount.toString().concat('₴')
    },
    {
      title: 'Время',
      dataIndex: 'time',
      key: 'time'
    }
  ];

  return (
    <div className="PayoutsTable">
      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={payouts}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: 'Ничего не найдено' }}
      />
    </div>
  );
};

export default PayoutsTable;
