const config = {
  dev: {
    api: {
      url: 'https://localhost/api'
    },
    static: {
      url: 'https://localhost'
    }
  },
  prod: {
    api: {
      url: 'https://62.171.131.198/api'
    },
    static: {
      url: 'https://62.171.131.198'
    }
  }
};

export default process.env.NODE_ENV === 'production' ? config.prod : config.dev;
