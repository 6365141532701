import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Login from '../Login/Login';
import NotFound from '../common/NotFound';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../Dashboard/Dashboard';
import PhoneNumbersTable from '../Dashboard/PhoneNumbers/PhoneNumbersTable';
import Mailing from '../Dashboard/Mailing/Mailing';
import UsersTable from '../Dashboard/Users/UsersTable';
import OrdersTable from '../Dashboard/Orders/OrdersTable';
import Settings from '../Dashboard/Settings/Settings';
import InvoicesTable from '../Dashboard/Invoices/InvoicesTable';
import PayoutRequestsTable from '../Dashboard/PayoutRequests/PayoutRequestsTable';
import PayoutsTable from '../Dashboard/Payouts/PayoutsTable';
import { RequestOrderType } from '../../types/enums';
import PaymentsToBalanceTable from '../Dashboard/PaymentsToBalance/PaymentsToBalanceTable';

interface IRouterProps {
  authorized: boolean;
}

const Router: React.FC<IRouterProps> = ({ authorized }) => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/" authorized={authorized} component={() => (
          <Redirect to="/phone-numbers" />
        )} />
        <ProtectedRoute exact path="/phone-numbers" authorized={authorized} component={() => (
          <Dashboard>
            <PhoneNumbersTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/users" authorized={authorized} component={() => (
          <Dashboard>
            <UsersTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/invoices" authorized={authorized} component={() => (
          <Dashboard>
            <InvoicesTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/orders" authorized={authorized} component={() => (
          <Dashboard>
            <OrdersTable orderType={RequestOrderType.ORDER} />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/bookings" authorized={authorized} component={() => (
          <Dashboard>
            <OrdersTable orderType={RequestOrderType.BOOKING} />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/payout-requests" authorized={authorized} component={() => (
          <Dashboard>
            <PayoutRequestsTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/payout-requests-history" authorized={authorized} component={() => (
          <Dashboard>
            <PayoutsTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/payments-to-balance-history" authorized={authorized} component={() => (
          <Dashboard>
            <PaymentsToBalanceTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/mailing" authorized={authorized} component={() => (
          <Dashboard>
            <Mailing />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/settings" authorized={authorized} component={() => (
          <Dashboard>
            <Settings />
          </Dashboard>
        )} />
        <Route exact path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
