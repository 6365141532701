import api from './api';
import { IPhoneNumber } from '../types/phoneNumbers';
import { IInvoice } from '../types/invoices';

export const fetchInvoices = async (): Promise<IInvoice[]> => {
  const { data } = await api.get('/invoices');
  return data.invoices;
};

export const createInvoice = async ({ surname, invoice }): Promise<void> => {
  await api.put('/invoices', { surname, invoice });
};

export const deleteInvoices = async (ids: string[]): Promise<void> => {
  await api.delete('/invoices', {
    data: { ids }
  });
};
