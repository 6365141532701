import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { Button, Col, Layout, Menu, Row } from 'antd';
import { PhoneOutlined, AlertOutlined, QuestionCircleOutlined, MailOutlined, UserOutlined, MergeCellsOutlined, SettingOutlined, BorderInnerOutlined, PayCircleOutlined, BarChartOutlined, HistoryOutlined } from '@ant-design/icons';

import AuthContext from '../../contexts/AuthContext';
import '../../styles/Dashboard.css';

const { Header, Content, Sider } = Layout;

const menu = [
  {
    text: 'Номера телефонов',
    icon: <PhoneOutlined />,
    url: '/phone-numbers'
  },
  {
    text: 'Пользователи',
    icon: <UserOutlined />,
    url: '/users'
  },
  {
    text: 'Номера ТТН',
    icon: <BorderInnerOutlined />,
    url: '/invoices'
  },
  {
    text: 'Заказы',
    icon: <MergeCellsOutlined />,
    url: '/orders'
  },
  {
    text: 'Бронирования',
    icon: <MergeCellsOutlined />,
    url: '/bookings'
  },
  {
    text: 'Запросы на выплату',
    icon: <PayCircleOutlined />,
    url: '/payout-requests'
  },
  {
    text: 'История запросов на выплату',
    icon: <BarChartOutlined />,
    url: '/payout-requests-history'
  },
  {
    text: 'История зачислений на баланс',
    icon: <HistoryOutlined />,
    url: '/payments-to-balance-history'
  },
  {
    text: 'Рассылка',
    icon: <MailOutlined />,
    url: '/mailing'
  },
  {
    text: 'Настройки',
    icon: <SettingOutlined />,
    url: '/settings'
  }
];

const Dashboard: React.FC = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const onMenuSelected = ({ key }) => {
    history.push(key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Menu */}
      <Sider
        breakpoint="md"
        collapsible
        collapsed={menuCollapsed}
        onCollapse={menuCollapsed => setMenuCollapsed(menuCollapsed)}
      >
        <div className="Logo">
          <Row align="middle">
            <Col span={menuCollapsed ? 24 : 'auto'}>
              <img src="/logo.png" alt="Logo" style={{ border: 0, borderRadius: '100%' }} />
            </Col>
            <Col span={menuCollapsed ? 0 : 'auto'}>
              <h1>Admin panel</h1>
            </Col>
          </Row>
        </div>

        <Menu
          theme="dark"
          selectedKeys={[history.location.pathname]}
          onSelect={onMenuSelected}
          mode="inline"
        >
          {menu.map((item, i) =>
            (
              <Menu.Item key={item.url} icon={item.icon}>
                <span>{item.text}</span>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>

      <Layout>
        {/* Header */}
        <Header style={{ padding: 0 }}>
          <Row justify="end">
            <Col>
              <Button
                danger
                className="SignOutButton"
                type="primary"
                onClick={authContext.logout}
              >
                Выйти
              </Button>
            </Col>
          </Row>
        </Header>

        {/* Content */}
        <Content style={{ margin: '15px' }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
