import api from './api';
import { IOrder } from '../types/orders';
import { RequestOrderType } from '../types/enums';

export const fetchOrders = async (type?: RequestOrderType): Promise<IOrder[]> => {
  const { data } = await api.get('/orders', {
    params: { type }
  });
  return data.orders;
};

export const updateOrder = async (id: string, data: Partial<IOrder>): Promise<void> => {
  await api.post('/orders', { id, data });
};

export const paySum = async (id: string, paidSum: string): Promise<void> => {
  await api.post('/orders/paySum', { id, paidSum });
};

export const sendMessage = async (orderId: string, message: string): Promise<void> => {
  await api.post('/orders/sendMessage', { id: orderId, message });
};
