import config from '../config';
import axios from 'axios';

const apiURL = config.api.url;
const staticURL = config.static.url;

const api = axios.create({
  baseURL: apiURL,
  withCredentials: true
});

export { apiURL, staticURL };
export default api;
