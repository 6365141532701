import React from 'react';
import { Form, Input, message as antMessage, Modal } from 'antd';
import { sendMessage } from '../../../api/orders';

interface ICreatorProps {
  orderId: string;
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const SendMessageModal: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ message }) => {
    try {
      await sendMessage(props.orderId, message);
      antMessage.success('Сообщение успешно отправлено!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      antMessage.error('Не удалось отправить сообщение');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Отправка сообщения"
      okText="Отправить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="message"
          label="Сообщение"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести сообщение для отправки!'
            }
          ]}
        >
          <Input.TextArea placeholder="Введите сообщение для отправки" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SendMessageModal;
