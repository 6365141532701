import api from './api';
import { ITelegramUser } from '../types/telegramUsers';

export const fetchUsers = async (): Promise<ITelegramUser[]> => {
  const { data } = await api.get('/users');
  return data.users;
};

export const updateUser = async (id: string, data: Partial<ITelegramUser>): Promise<any> => {
  await api.post('/users', { id, data });
};

