import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Form, message, Input, InputNumber } from 'antd';

import { IVar } from '../../../types/vars';
import { updateVariable, fetchVariable } from '../../../api/vars';
import { useForm } from 'antd/es/form/Form';

const varsToEdit = [
  {
    caption: 'Ключ API НП',
    name: 'npAPIKey'
  },
  {
    caption: 'ID таблицы',
    name: 'spreadsheetsDocID'
  },
  {
    caption: 'Срок действия брони (мин.)',
    name: 'bookingLifetimeInMinutes',
    inputType: 'number'
  },
  {
    caption: 'Сумма предоплаты',
    name: 'prepaySum',
    inputType: 'number'
  },
  {
    caption: 'Платёжные реквизиты',
    name: 'paymentRequisites',
    inputType: 'textarea'
  }
];

const Settings: React.FC = () => {
  const [form] = useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [vars, setVars] = useState<IVar[]>([]);

  const updateData = async () => {
    setLoading(true);

    try {
      const vars = (
        await Promise.allSettled(
          varsToEdit.map(v => fetchVariable(v.name))
        )
      ).filter(({ status }) => status === 'fulfilled')
       // @ts-ignore
       .map(({ value }) => value);

      setVars(vars);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить настройки');
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateData().then(() => form.resetFields());
  }, []);

  const onSubmit = async (formVars) => {
    try {
      const updates: Promise<void>[] = [];

      for (const key in formVars) {
        if (formVars.hasOwnProperty(key)) {
          //@ts-ignore
          const oldValue = vars?.find(v => v.name === key)?.value ?? '';
          const newValue = formVars[key];

          if (newValue !== oldValue) {
            updates.push(updateVariable(key, formVars[key]));
          }
        }
      }

      await Promise.all(updates);
      message.success('Настройки успешно сохранены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось сохранить настройки');
    }
    finally {
      updateData().then(() => form.resetFields());
      onCancel();
    }
  };

  const onCancel = () => history.push('/');

  const getInputComponent = (inputType) => {
    switch (inputType) {
      case 'number':
        return <InputNumber />;
      case 'textarea':
        return <Input.TextArea />;
      default:
        return <Input />;
    }
  };

  return (
    <div className="Settings">
      <Modal
        title="Настройки"
        okText="Сохранить"
        cancelText="Отменить"
        destroyOnClose={true}
        visible={true}
        onCancel={onCancel}
        onOk={form.submit}
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 11 }}
          form={form}
          initialValues={
            Object.fromEntries(vars.map(v => [v.name, v.value]))
          }
          onFinish={onSubmit}
        >
          {
            varsToEdit.map((v, i) => (
              <Form.Item
                key={v.name}
                name={v.name}
                label={v.caption}
                rules={[
                  {
                    required: true,
                    message: 'Значение не может быть пустым!'
                  }
                ]}
              >
                {
                  getInputComponent(v.inputType)
                }
              </Form.Item>
            ))
          }
        </Form>
      </Modal>
    </div>
  );
};

export default Settings;
