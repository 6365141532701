import React from 'react';
import { Form, Input, message, Modal, InputNumber } from 'antd';
import { paySum } from '../../../api/orders';
import { payout } from '../../../api/payoutRequests';

interface IEditorProps {
  id: string;
  visible: boolean;
  initialBalance: number;
  onEdit: (...args) => any;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const BalanceEditorModal: React.FC<IEditorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ balance }) => {
    try {
      await props.onEdit(props.id, { balance });
      message.success('Баланс успешно изменён!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось изменить баланс');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Изменение баланса"
      okText="Изменить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={{
          balance: props.initialBalance
        }}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="balance"
          label="Баланс"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести баланс!'
            }
          ]}
        >
          <InputNumber placeholder="Введите баланс" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BalanceEditorModal;
