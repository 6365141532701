import React, { useState, useEffect } from 'react';

import { message, Row, Col, Table, Button, Space } from 'antd';

import { getColumnSearchProps } from '../../../helpers/tableSearch';
import InvoiceCreator from './InvoiceCreator';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { IInvoice } from '../../../types/invoices';
import { fetchInvoices, deleteInvoices } from '../../../api/invoices';

const InvoicesTable: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [invoices, setInvoices] = useState<IInvoice[]>();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [creatorVisible, setCreatorVisible] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const invoices = await fetchInvoices();
      setInvoices(invoices);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить ТТН');
    }
    finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await deleteInvoices(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success('Номера ТТН успешно удалены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить номера ТТН');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Номер ТТН',
      dataIndex: 'invoice',
      key: 'invoice',
      sorter: (a, b) => a.invoice?.localeCompare(b.invoice),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      ...getColumnSearchProps('invoice', setSearchText, setSearchedColumn)
    },
    {
      title: 'Фамилия',
      dataIndex: 'surname',
      key: 'surname',
      sorter: (a, b) => a.surname?.localeCompare(b.surname),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      ...getColumnSearchProps('surname', setSearchText, setSearchedColumn)
    },
    {
      title: 'Дата добавления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (createdAt) => new Date(createdAt).toLocaleString()
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: keys => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row justify="center">
      <Col span={24}>
        <div style={{ marginBottom: 16 }}>
          <Space size="small">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setCreatorVisible(true)}
            >
              Добавить ТТН
            </Button>

            <Button
              danger
              type="primary"
              onClick={onDelete}
              disabled={!hasSelected}
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>

            <span style={{ marginLeft: 8 }}>
              {hasSelected && `Выбрано ${selectedRowKeys.length} номер(ов)`}
            </span>
          </Space>
        </div>

        <Table
          loading={loading}
          //@ts-ignore
          columns={columns}
          //@ts-ignore
          dataSource={invoices}
          rowSelection={rowSelection}
          rowKey="_id"
          locale={{
            filterReset: 'Сбросить',
            filterConfirm: 'ОК',
            emptyText: 'Ничего не найдено'
          }}
          scroll={{ x: true }}
        />

        <InvoiceCreator
          visible={creatorVisible}
          onCancel={() => setCreatorVisible(false)}
          afterSubmit={updateData}
        />
      </Col>
    </Row>
  );
};

export default InvoicesTable;
