export enum DeliveryCompany {
  NOVA_POSHTA,
  UKR_POSHTA,
}

export enum DeliveryType {
  BY_OFFICE,
  BY_ADDRESS,
  BY_PARCEL_MACHINE,
}

export enum OrderStatus {
  BOOKED = 0,
  WAITING_FOR_ARRIVAL_TO_POST_OFFICE = 1,
  DELETED = 2,
  FAILED = 3,
  IN_CITY_XXX = 4,
  ON_WAY_TO_CITY_XXX = 5,
  DELIVERED_TO_DESTINATION_CITY = 6,
  DELIVERED_TO_DESTINATION_OFFICE = 7,
  RECEIVED_BY_RECIPIENT = 8,
  PACKING = 9,
  REJECTED = 10,
  STORAGE_STOPPED = 11,
  // Our service statuses
  FAILED_TO_CREATE_INVOICE = 1000,
  WAITING_FOR_TRACKING = 1002,
  BOOKED_WITHOUT_RECIPIENT_DATA = 1003,
  WAITING_FOR_RECIPIENT_DATA = 1004,
}

export enum PaymentType {
  BY_CARD,
  PREPAY_BY_CARD,
  PREPAY_FROM_BALANCE,
  FROM_BALANCE,
  PREPAY_FROM_BALANCE_WITH_CARD,
  FROM_BALANCE_WITH_CARD
}

export enum RequestOrderType {
  BOOKING = 1,
  ORDER = 2
}
