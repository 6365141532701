import React, { useState, useEffect } from 'react';

import { message, Table, Col, Button } from 'antd';

import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { IPayoutRequest } from '../../../types/payoutRequests';
import { fetchPayoutRequests, deletePayoutRequests, payout } from '../../../api/payoutRequests';
import { DeleteOutlined } from '@ant-design/icons';
import PaySumModal from '../Orders/PaySumModal';
import GetPayoutTimeModal from './GetPayoutTimeModal';

const PayoutRequestsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [payoutRequests, setPayoutRequests] = useState<IPayoutRequest[]>();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const [selectedPayoutRequestId, setSelectedPayoutRequestId] = useState<string>();
  const [creatorVisible, setCreatorVisible] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const payoutRequests = await fetchPayoutRequests();
      setPayoutRequests(payoutRequests);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить заявки на выплату');
    }
    finally {
      setLoading(false);
    }
  };

  const onPayout = async (id: string) => {
    setSelectedPayoutRequestId(id);
    setCreatorVisible(true);
  };

  const onDelete = async () => {
    try {
      await deletePayoutRequests(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success('Запросы успешно удалены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить запросы');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'payout',
      key: 'payout',
      render: (_, { _id, amount, user }) => (
        <Button
          onClick={() => onPayout(_id)}
          disabled={amount > user.balance}
        >
          Выплатить
        </Button>
      )
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (amount) => amount.toString().concat('₴'),
      ...getColumnSearchProps('amount', setSearchText, setSearchedColumn)
    },
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user.name?.localeCompare(b.user.name),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, user }) => {
        const { name = '—', username } = user;
        return username ? <a href={`https://t.me/${username}`}>{name}</a> : name;
      }
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => a.user.balance - b.user.balance,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, user }) => (user.balance ?? 0).toString().concat('₴')
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      sorter: (a, b) => a.comment?.localeCompare(b.comment),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      ...getColumnSearchProps('comment', setSearchText, setSearchedColumn)
    },
    {
      title: 'Дата добавления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (createdAt) => new Date(createdAt).toLocaleString()
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: keys => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Button
          danger
          type="primary"
          onClick={onDelete}
          disabled={!hasSelected}
          icon={<DeleteOutlined />}
        >
          Удалить
        </Button>
      </div>

      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        //@ts-ignore
        dataSource={payoutRequests}
        rowSelection={rowSelection}
        rowKey="_id"
        locale={{
          filterReset: 'Сбросить',
          filterConfirm: 'ОК',
          emptyText: 'Ничего не найдено'
        }}
        scroll={{ x: true }}
      />

      <GetPayoutTimeModal
        payoutRequestId={selectedPayoutRequestId ?? ''}
        visible={creatorVisible}
        onCancel={() => setCreatorVisible(false)}
        afterSubmit={updateData}
      />
    </>
  );
};

export default PayoutRequestsTable;
