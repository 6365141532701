import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { createInvoice } from '../../../api/invoices';

interface ICreatorProps {
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const InvoiceCreator: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ rawInvoices }) => {
    try {
      let matchedInvoices = 0;

      await Promise.all(
        rawInvoices.split('\n').map(rawInvoice => {
          const match = rawInvoice.match(/(\S+)\s+(\S+)\s+(\d+)/);
          if (!match) {
            return new Promise<void>((resolve) => resolve());
          }

          matchedInvoices++;

          const nameParts = match.slice(1, -1);
          const fullName = nameParts.join(' ');
          const invoice = match[match.length - 1];

          return createInvoice({ surname: fullName, invoice });
        })
      );

      if (matchedInvoices > 0) {
        message.success('ТТН успешно добавлены!');
        form.resetFields();
      }
      else {
        message.warn('ТТН не были добавлены');
      }
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось добавить ТТН');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Добавление ТТН"
      className="InvoiceCreator"
      okText="Добавить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="rawInvoices"
          label="Номер ТТН"
          rules={[
            {
              required: true,
              message: 'Обязательное поле!'
            }
          ]}
        >
          <Input.TextArea placeholder="Иванов Иван 11111111111111" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InvoiceCreator;
