import api from './api';
import { IPhoneNumber } from '../types/phoneNumbers';

export const fetchPhoneNumbers = async (): Promise<IPhoneNumber[]> => {
  const { data } = await api.get('/phoneNumbers');
  return data.phoneNumbers;
};

export const createPhoneNumber = async (phoneNumber: string): Promise<void> => {
  await api.put('/phoneNumbers', { phoneNumber });
};

export const deletePhoneNumbers = async (ids: string[]): Promise<void> => {
  await api.delete('/phoneNumbers', {
    data: { ids }
  });
};
