import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { paySum } from '../../../api/orders';

interface ICreatorProps {
  orderId: string;
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const PaySumModal: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ paidSum }) => {
    try {
      await paySum(props.orderId, paidSum);
      message.success('Сумма успешно выплачена!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось выплатить сумму');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Выплата"
      okText="Выплатить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="paidSum"
          label="Сумма"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести сумму для выплаты!'
            }
          ]}
        >
          <Input placeholder="Введите сумму для выплаты" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaySumModal;
