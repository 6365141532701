import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { paySum } from '../../../api/orders';
import { payout } from '../../../api/payoutRequests';

interface ICreatorProps {
  payoutRequestId: string;
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const GetPayoutTimeModal: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ time }) => {
    try {
      await payout(props.payoutRequestId, time);
      message.success('Выплата успешно проведена!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось провести выплату');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Выплата"
      okText="Выплатить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="time"
          label="Время выплаты"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести время выплаты!'
            }
          ]}
        >
          <Input placeholder="Введите время выплаты" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GetPayoutTimeModal;
