import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { createPhoneNumber } from '../../../api/phoneNumbers';
import { phoneNumberRegexp } from '../../../helpers/regexp';

interface ICreatorProps {
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const PhoneNumberCreator: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ phoneNumber }: any) => {
    try {
      await createPhoneNumber(phoneNumber);
      message.success('Номер телефона успешно добавлен!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось добавить номер телефона');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Добавление номера телефона"
      className="PhoneNumberCreator"
      okText="Добавить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="phoneNumber"
          label="Номер телефона"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести номер телефона!'
            },
            {
              pattern: phoneNumberRegexp,
              message: 'Необходимо ввести в формате 380XXXXXXXXX!'
            }
          ]}
        >
          <Input placeholder="Введите номер телефона" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PhoneNumberCreator;
