import React, { useEffect, useState } from 'react';
import { message, Table, Space, Button } from 'antd';
import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { IPhoneNumber } from '../../../types/phoneNumbers';
import { fetchPhoneNumbers, deletePhoneNumbers } from '../../../api/phoneNumbers';
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import PhoneNumberCreator from './PhoneNumberCreator';

const PhoneNumbersTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [creatorVisible, setCreatorVisible] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const phoneNumbers = await fetchPhoneNumbers();
      setPhoneNumbers(phoneNumbers);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось загрузить данные');
    }
    finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await deletePhoneNumbers(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success('Номера успешно удалены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить номера');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      ...getColumnSearchProps('phoneNumber', setSearchText, setSearchedColumn)
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: keys => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="PhoneNumbersTable">
      <div style={{ marginBottom: 16 }}>
        <Space size="small">
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => setCreatorVisible(true)}
            disabled={hasSelected}
          >
            Добавить
          </Button>

          <Button
            danger
            type="primary"
            onClick={onDelete}
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            Удалить
          </Button>

          <span style={{ marginLeft: 8 }}>
					  {hasSelected && `Выбрано ${selectedRowKeys.length} номер(ов)`}
					</span>
        </Space>
      </div>

      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={phoneNumbers}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: 'Ничего не найдено' }}
      />

      <PhoneNumberCreator
        visible={creatorVisible}
        onCancel={() => setCreatorVisible(false)}
        afterSubmit={updateData}
      />
    </div>
  );
};

export default PhoneNumbersTable;
