import React, { useState, useEffect } from 'react';

import { message, Table, Button, Typography, Space, Select } from 'antd';

import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { IOrder } from '../../../types/orders';
import { fetchOrders, updateOrder } from '../../../api/orders';
import PaySumModal from './PaySumModal';
import { apiURL } from '../../../api/api';
import { TableOutlined, MailOutlined, PayCircleOutlined, CreditCardOutlined } from '@ant-design/icons';
import { ORDER_STATUSES, PAYMENT_TYPES, DELIVERY_COMPANIES, DELIVERY_TYPES } from '../../../constants';
import { RequestOrderType } from '../../../types/enums';
import SendMessageModal from './SendMessageModal';

interface IOrdersTableProps {
  orderType?: RequestOrderType;
}

const OrdersTable: React.FC<IOrdersTableProps> = ({ orderType }) => {
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState<IOrder[]>();
  const [selectedOrderId, setSelectedOrderId] = useState<string>();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const [paySumModalVisible, setPaySumModalVisible] = useState(false);
  const [sendMessageModalVisible, setSendMessageModalVisible] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const orders = await fetchOrders(orderType);
      setOrders(orders);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить заказы');
    }
    finally {
      setLoading(false);
    }
  };

  const onPaySum = async (id: string) => {
    setSelectedOrderId(id);
    setPaySumModalVisible(true);
  };

  const onSendMessage = async (id: string) => {
    setSelectedOrderId(id);
    setSendMessageModalVisible(true);
  };

  const onEdit = async (id: string, data: Partial<IOrder>) => {
    try {
      await updateOrder(id, data);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось изменить заказ');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_, order) => (
        <Space>
          <Button onClick={() => onPaySum(order._id)}>
            <CreditCardOutlined />
          </Button>

          <Button onClick={() => onSendMessage(order._id)}>
            <MailOutlined />
          </Button>
        </Space>
      )
    },
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user.name?.localeCompare(b.user.name),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, user }) => {
        const { name = '—', username } = user;
        return username ? <a href={`https://t.me/${username}`}>{name}</a> : name;
      }
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, status }) => (
        <Select
          loading={loading}
          defaultValue={status}
          notFoundContent="Не удалось получить список статусов"
          onChange={(status) => onEdit(_id, { status })}
          style={{
            minWidth: 220
          }}
        >
          {
            ORDER_STATUSES?.map(({ status, text }) => {
              return <Select.Option key={status} value={status}>{text}</Select.Option>;
            })
          }
        </Select>
      )
    },
    {
      title: 'Тип оплаты',
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: (a, b) => (a.paymentType || -1) - (b.paymentType || -1),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (paymentType) => {
        const { text } = PAYMENT_TYPES.find(obj => obj.type === paymentType) || {};
        return text;
      }
    },
    {
      title: 'Компания доставки',
      dataIndex: 'deliveryCompany',
      key: 'deliveryCompany',
      sorter: (a, b) => (a.deliveryCompany || -1) - (b.deliveryCompany || -1),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (deliveryCompany) => {
        const { text } = DELIVERY_COMPANIES.find(obj => obj.type === deliveryCompany) || {};
        return text;
      }
    },
    {
      title: 'Тип доставки',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      sorter: (a, b) => (a.deliveryType || -1) - (b.deliveryType || -1),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (deliveryType) => {
        const { text } = DELIVERY_TYPES.find(obj => obj.type === deliveryType) || {};
        return text;
      }
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      key: 'sum',
      sorter: (a, b) => a.sum - b.sum,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (sum) => sum.toString().concat('₴'),
      ...getColumnSearchProps('sum', setSearchText, setSearchedColumn)
    },
    {
      title: 'Оплачено картой',
      dataIndex: 'paidByCard',
      key: 'paidByCard',
      sorter: (a, b) => (a.paidByCard || -1) - (b.paidByCard || -1),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (paidByCard) => paidByCard?.toString().concat('₴') ?? '—'
    },
    {
      title: 'Оплачено с баланса',
      dataIndex: 'paidFromBalance',
      key: 'paidFromBalance',
      sorter: (a, b) => (a.paidFromBalance || -1) - (b.paidFromBalance || -1),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (paidFromBalance) => paidFromBalance?.toString().concat('₴') ?? '—'
    },
    {
      title: 'Сумма наложенного платежа',
      dataIndex: 'backwardMoneyDeliverySum',
      key: 'backwardMoneyDeliverySum',
      sorter: (a, b) => a.backwardMoneyDeliverySum ?? 0 - b.backwardMoneyDeliverySum ?? 0,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (sum) => sum?.toString().concat('₴') ?? '–',
      ...getColumnSearchProps('backwardMoneyDeliverySum', setSearchText, setSearchedColumn)
    },
    {
      title: 'Выплачено',
      dataIndex: 'paidSum',
      key: 'paidSum',
      sorter: (a, b) => a.paidSum - b.paidSum,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (paidSum) => paidSum.toString().concat('₴'),
      ...getColumnSearchProps('paidSum', setSearchText, setSearchedColumn)
    },
    {
      title: 'Заказ',
      dataIndex: 'order',
      key: 'order',
      render: (_, { items }) => items.map(({ code, size, quantity }) => `${quantity} x ${code}, ${size} размер`).join('; ')
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      ...getColumnSearchProps('comment', setSearchText, setSearchedColumn)
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      key: 'weight',
      render: (weight) => weight.toString().concat(' ', 'кг'),
      ...getColumnSearchProps('weight', setSearchText, setSearchedColumn)
    },
    {
      title: 'Имя получателя',
      dataIndex: 'recipientName',
      key: 'recipientName',
      render: (recipientName) => recipientName || '—',
      ...getColumnSearchProps('recipientName', setSearchText, setSearchedColumn)
    },
    {
      title: 'Номер телефона получателя',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
      render: (recipientPhone) => recipientPhone || '—',
      ...getColumnSearchProps('recipientPhone', setSearchText, setSearchedColumn)
    },
    {
      title: 'Город получателя',
      dataIndex: 'recipientCity',
      key: 'recipientCity',
      render: (recipientCity) => recipientCity || '—',
      ...getColumnSearchProps('recipientCity', setSearchText, setSearchedColumn)
    },
    {
      title: 'Данные для доставки',
      dataIndex: 'deliveryDestination',
      key: 'deliveryDestination',
      ...getColumnSearchProps('deliveryDestination', setSearchText, setSearchedColumn)
    },
    {
      title: 'ТТН',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (_, { _id, invoice }) => (
        <Typography.Text editable={{
          onChange: invoice => onEdit(_id, { invoice })
        }}>
          {invoice || '—'}
        </Typography.Text>
      ),
      ...getColumnSearchProps('invoice', setSearchText, setSearchedColumn)
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      ...getColumnSearchProps('paymentDate', setSearchText, setSearchedColumn)
    },
    {
      title: 'Скриншот оплаты',
      dataIndex: 'paymentScreenshot',
      key: 'paymentScreenshot',
      render: (_, { _id, paymentScreenshot }) => (
        paymentScreenshot
          ? <a href={paymentScreenshot} rel="noopener norefferer" target="_blank">Просмотреть</a>
          : '—'
      )
    }
  ];

  return (
    <>
      <Button
        type="primary"
        icon={<TableOutlined />}
        href={apiURL.concat('/orders/waitingForArrivalToPostOfficeExcelTable')}
        style={{ marginBottom: 8 }}
      >
        Заказы, ожид. отправл.
      </Button>

      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        //@ts-ignore
        dataSource={orders}
        rowKey="_id"
        locale={{
          filterReset: 'Сбросить',
          filterConfirm: 'ОК',
          emptyText: 'Ничего не найдено'
        }}
        scroll={{ x: true }}
      />

      <PaySumModal
        orderId={selectedOrderId ?? ''}
        visible={paySumModalVisible}
        onCancel={() => setPaySumModalVisible(false)}
        afterSubmit={updateData}
      />

      <SendMessageModal
        orderId={selectedOrderId ?? ''}
        visible={sendMessageModalVisible}
        onCancel={() => setSendMessageModalVisible(false)}
        afterSubmit={updateData}
      />
    </>
  );
};

export default OrdersTable;
