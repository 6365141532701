import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { IPaymentToBalance } from '../../../types/paymentsToBalance';
import { fetchPaymentsToBalance } from '../../../api/paymentsToBalance';

const PaymentsToBalanceTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [paymentsToBalance, setPaymentsToBalance] = useState<IPaymentToBalance[]>();

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const paymentsToBalance = await fetchPaymentsToBalance();
      setPaymentsToBalance(paymentsToBalance);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось загрузить данные');
    }
    finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user.fullName.localeCompare(b.user.fullName),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { user }) => {
        const { fullName = '—', username } = user;
        return username ? <a href={`https://t.me/${username}`}>{fullName}</a> : fullName;
      }
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (_, { user }) => user?.phoneNumber ?? '—'
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => amount.toString().concat('₴')
    },
    {
      title: 'Заказ (дата создания)',
      dataIndex: 'date',
      key: 'date',
      render: (_, { order }) => order ? new Date(order?.createdAt).toLocaleString() : '—'
    },
    {
      title: 'Время создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      render: (createdAt) => new Date(createdAt).toLocaleString()
    }
  ];

  return (
    <div className="PayoutsTable">
      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={paymentsToBalance}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: 'Ничего не найдено' }}
      />
    </div>
  );
};

export default PaymentsToBalanceTable;
