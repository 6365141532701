import React from 'react';
import { Col, Button, Checkbox, Row, Form, Input, Upload, message } from 'antd';
import { createMailing } from '../../../api/mailing';

const Mailing: React.FC = () => {
  const onSubmit = async ({ text }: any) => {
    message.loading('Рассылаю сообщение', 1e5);

    try {
      await createMailing(text);

      message.destroy();
      message.success('Рассылка успешно проведена!');
    }
    catch (err) {
      console.error(err);

      message.destroy();
      message.error('Не удалось провести рассылку');
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={20} md={16} lg={12}>
        <h1>Рассылка</h1>

        <Form onFinish={onSubmit}>
          <Form.Item
            name="text"
            rules={[
              {
                required: true,
                message: 'Вы должны ввести сообщение!'
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 12 }}
              placeholder="Введите текст сообщения..."
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Mailing;
