import api from './api';
import { IVar } from '../types/vars';

export const fetchVariable = async (name: string): Promise<IVar> => {
  const { data } = await api.get('/vars', { params: { name } });
  return data.variable;
};

export const createVariable = async (name: string, value: any): Promise<void> => {
  await api.put('/vars', { name, value });
};

export const updateVariable = async (name: string, value: any): Promise<any> => {
  await api.post('/vars', { name, value });
};
