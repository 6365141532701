import api from './api';
import { IPayoutRequest } from '../types/payoutRequests';

export const fetchPayoutRequests = async (): Promise<IPayoutRequest[]> => {
  const { data } = await api.get('/payoutRequests');
  return data.payoutRequests;
};

export const payout = async (id: string, time: string): Promise<void> => {
  await api.post('/payout', { id, time });
};

export const deletePayoutRequests = async (ids: string[]): Promise<void> => {
  await api.delete('/payoutRequests', {
    data: { ids }
  });
};
