import React, { useState, useEffect } from 'react';

import { message, Row, Col, Table, Typography, Space, Button } from 'antd';

import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { ITelegramUser } from '../../../types/telegramUsers';
import { fetchUsers, updateUser } from '../../../api/users';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { apiURL } from '../../../api/api';
import BalanceEditorModal from './BalanceEditorModal';

const UsersTable: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState<ITelegramUser[]>();

  const [balanceEditorVisible, setBalanceEditorVisible] = useState(false);
  const [editingId, setEditingId] = useState();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const users = await fetchUsers();
      setUsers(users);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить пользователей');
    }
    finally {
      setLoading(false);
    }
  };

  const onEdit = async (id: string, data: Partial<ITelegramUser>) => {
    try {
      await updateUser(id, data);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось изменить пользователя');
    }
    finally {
      const keys = Object.keys(data);
      const shouldUpdate = !(keys.includes('balance') && keys.length === 1);
      if (shouldUpdate) {
        updateData();
      }
    }
  };

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_, { _id }) => (
        <Space>
          <Button
            icon={<DownloadOutlined />}
            href={apiURL.concat(`/users/userExcelTable?id=${_id}`)}
          >
            Таблица
          </Button>
        </Space>
      )
    },
    {
      title: 'Имя',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => a.fullName?.localeCompare(b.fullName),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, fullName }) => (
        <Typography.Text editable={{
          onChange: fullName => onEdit(_id, { fullName })
        }}>
          {fullName || '—'}
        </Typography.Text>
      ),
      ...getColumnSearchProps('fullName', setSearchText, setSearchedColumn)
    },
    {
      title: 'Юзернейм',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username?.localeCompare(b.username),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (username) => username ? <a href={`https://t.me/${username}`}>{username}</a> : '—',
      ...getColumnSearchProps('username', setSearchText, setSearchedColumn)
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, phoneNumber }) => phoneNumber || '—',
      ...getColumnSearchProps('phoneNumber', setSearchText, setSearchedColumn)
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
      render: (_, { _id, balance }) => (
        <>
          {balance} ₴

          <Button type="link" style={{ marginLeft: -8 }}>
            <EditOutlined
              onClick={() => {
                setEditingId(_id);
                setBalanceEditorVisible(true);
              }}
            />
          </Button>
        </>
      )
    }
  ];

  const editingUser = users?.find(user => user._id === editingId);

  return (
    <Row justify="center">
      <Col span={24}>
        <Table
          loading={loading}
          //@ts-ignore
          columns={columns}
          //@ts-ignore
          dataSource={users}
          rowKey="_id"
          locale={{
            filterReset: 'Сбросить',
            filterConfirm: 'ОК',
            emptyText: 'Ничего не найдено'
          }}
          scroll={{ x: true }}
        />
      </Col>

      {
        editingUser &&
				<BalanceEditorModal
					id={editingUser._id}
					initialBalance={editingUser.balance}
					visible={balanceEditorVisible}
					onCancel={() => setBalanceEditorVisible(false)}
					onEdit={onEdit}
					afterSubmit={updateData}
				/>
      }
    </Row>
  );
};

export default UsersTable;
