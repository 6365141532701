import { OrderStatus, PaymentType, DeliveryType, DeliveryCompany } from '../types/enums';

export const ORDER_STATUSES = [
  {
    status: OrderStatus.BOOKED,
    text: 'Забронировано'
  },
  {
    status: OrderStatus.FAILED,
    text: 'Ошибка'
  },
  {
    status: OrderStatus.REJECTED,
    text: 'Отклонено получателем'
  },
  {
    status: OrderStatus.WAITING_FOR_ARRIVAL_TO_POST_OFFICE,
    text: 'Ожидается прибытие в отделение'
  },
  {
    status: OrderStatus.DELETED,
    text: 'Удалено'
  },
  {
    status: OrderStatus.IN_CITY_XXX,
    text: 'Отправление в городе XXXX'
  },
  {
    status: OrderStatus.ON_WAY_TO_CITY_XXX,
    text: 'Отправление направляется в город XXXX'
  },
  {
    status: OrderStatus.DELIVERED_TO_DESTINATION_CITY,
    text: 'Отправление в городе получателя'
  },
  {
    status: OrderStatus.DELIVERED_TO_DESTINATION_OFFICE,
    text: 'Отправление в отделении получателя'
  },
  {
    status: OrderStatus.RECEIVED_BY_RECIPIENT,
    text: 'Получено'
  },
  {
    status: OrderStatus.PACKING,
    text: 'Отправление комплектуется'
  },
  {
    status: OrderStatus.STORAGE_STOPPED,
    text: 'Хранение прекращено'
  },
  {
    status: OrderStatus.FAILED_TO_CREATE_INVOICE,
    text: 'Не удалось создать ТТН'
  },
  {
    status: OrderStatus.WAITING_FOR_TRACKING,
    text: 'Ожидает отслеживания'
  },
  {
    status: OrderStatus.BOOKED_WITHOUT_RECIPIENT_DATA,
    text: 'Забронировано без данных получателя'
  },
  {
    status: OrderStatus.WAITING_FOR_RECIPIENT_DATA,
    text: 'Оплачено, ожидает ввода получателя'
  }
];

export const PAYMENT_TYPES = [
  {
    type: PaymentType.BY_CARD,
    text: 'Картой'
  },
  {
    type: PaymentType.FROM_BALANCE,
    text: 'С баланса'
  },
  {
    type: PaymentType.PREPAY_BY_CARD,
    text: 'Предоплата с карты + наложенный платёж'
  },
  {
    type: PaymentType.PREPAY_FROM_BALANCE,
    text: 'Предоплата с баланса + наложенный платёж'
  },
  {
    type: PaymentType.FROM_BALANCE,
    text: 'С баланса'
  },
  {
    type: PaymentType.PREPAY_FROM_BALANCE_WITH_CARD,
    text: 'Частичная предоплата с баланса'
  },
  {
    type: PaymentType.FROM_BALANCE_WITH_CARD,
    text: 'Частичная оплата с баланса'
  }
];

export const DELIVERY_TYPES = [
  {
    type: DeliveryType.BY_ADDRESS,
    text: 'Курьер'
  },
  {
    type: DeliveryType.BY_OFFICE,
    text: 'В отделение'
  },
  {
    type: DeliveryType.BY_PARCEL_MACHINE,
    text: 'Почтомат'
  }
];

export const DELIVERY_COMPANIES = [
  {
    type: DeliveryCompany.NOVA_POSHTA,
    text: 'Новая Почта'
  },
  {
    type: DeliveryCompany.UKR_POSHTA,
    text: 'УкрПочта'
  }
];
